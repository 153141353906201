<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card class="elevation-12 mx-auto my-10" max-width="40%">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <p class="font-weight-regular ml-5 mt-5 mb-5 mr-5">
          {{ msg }}
        </p>

        <v-card-actions>
          <v-btn color="success" @click="dialog = true" text
            >Resend email</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-layout>

    <v-dialog v-model="dialog" width="25%">
      <v-form v-model="isFormValid">
        <v-card>
          <v-card-title>
            <span class="headline">Email address</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  label="Email"
                  v-model="email"
                  required
                  :rules="emailRules"
                  spellcheck="false"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="ml-2 mb-2" @click="closeDialog()">Cancel</v-btn>
            <v-btn
              class="mb-2 mr-2"
              :loading="loading"
              color="primary"
              @click="resendVerificationEmail()"
              :disabled="!isFormValid"
              >Send</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import api from "@/services/api";
import { isValidEmail } from "@/lib/utils";

export default {
  data() {
    return {
      email: "",
      dialog: false,
      loading: false,
      regCode: "",
      isFormValid: false,
      title: "Registration Pending",
      msg:
        "Your registration is pending verification. Please check your inbox for an email with instructions to complete the process. If you are unable to find it in your inbox, please check your spam folder.",
      emailRules: [v => !!v || "Email required", v => this.validateEmail(v)]
    };
  },

  methods: {
    validateEmail(value) {
      return isValidEmail(value) || "Invalid email";
    },

    closeDialog() {
      this.dialog = false;
    },

    handleError() {
      this.loading = false;
      this.closeDialog();
    },

    resendVerificationEmail() {
      this.loading = true;

      api
        .updateContractData(this.regCode, {
          email: this.email,
          registration_code: this.regCode
        })
        .then(() => {
          api
            .getContractVerification(this.regCode)
            .then(() => {
              this.title = "Email sent";
              this.msg = `The verification email has been sent to ${this.email}. It may take a few minutes to appear in your inbox.`;
              this.closeDialog();
              this.loading = false;
            })
            .catch(() => {
              this.handleError();
            });
        })
        .catch(() => {
          this.handleError();
        });
    }
  },

  mounted() {
    this.email = this.$route.params.email || "";
    this.regCode = this.$route.params.code;
  }
};
</script>

<style></style>
